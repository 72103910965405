import React from "react";
import withLayout from "../../components/layout/withLayout";
import { withAppInsights } from "../../lib/AppInsights";
import { formatNumber, PageLink, pluralizeText } from "../../lib";
import Pages from "../../pages";
import { PopupModal } from "../../components/PopupModal";

class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: !props.user,
      carouselAnimating: false,
    };
  }

  componentDidMount() {
    this.initializeCarousel();
  }

  initializeCarousel = () => {
    let slides = Array.from(document.getElementsByClassName("slide"));
    slides.forEach((slide, i) => {
      if (slide.matches(":last-of-type")) {
        slide.style.order = -1;
      } else if (slide.matches(":nth-last-of-type(2)")) {
        slide.style.order = -2;
      } else {
        slide.style.order = i;
      }
    });
  };

  onCarouselNavClick = (e) => {
    e.preventDefault();
    let direction = e.target.classList.contains("next") ? "next" : "prev";
    if (this.state.carouselAnimating === false) {
      this.setState({ carouselAnimating: true });
      this.moveSlides(direction);
      this.slidesReset(direction);
    }
  };

  moveSlides = (direction) => {
    direction = direction ? direction : "next";
    let carousel = document.getElementsByClassName("carousel")[0];
    let currentLeft = parseFloat(
      window.getComputedStyle(carousel, null).getPropertyValue("margin-left"),
    );
    let slideWidth = document.getElementsByClassName("slide")[0].offsetWidth;
    slideWidth = direction === "next" ? slideWidth * -1 : slideWidth;
    carousel.classList.remove("noanimate");
    carousel.style.marginLeft = currentLeft + slideWidth + "px";
  };

  slidesReset = (direction) => {
    direction = direction ? direction : "next";
    let carousel = document.getElementsByClassName("carousel")[0];
    setTimeout(() => {
      carousel.classList.add("noanimate");
      let slides = Array.from(document.getElementsByClassName("slide"));
      if (direction === "next") {
        slides.forEach((slide) => {
          if (-2 === parseInt(slide.style.order)) {
            slide.style.order = slides.length - 3;
          } else {
            slide.style.order = parseInt(slide.style.order - 1);
          }
        });
      } else {
        slides.forEach((slide) => {
          if (slides.length === parseInt(slide.style.order) + 3) {
            slide.style.order = -2;
          } else {
            slide.style.order = parseInt(slide.style.order) + 1;
          }
        });
      }
      carousel.removeAttribute("style");
      this.setState({ carouselAnimating: false });
    }, 600);
  };

  handleClosePopup = () => {
    this.setState({ showPopup: false });
  };

  categories = [
    {
      desc: "Courses designed for the beginner, covering the basics of Jewish history, identity, thought, literature, practice, calendar, and lifecycle events.",
      img: "/images/courseCategories/jewish-literacy.jpg",
      key: "JewishLiteracy",
      title: "Jewish Literacy",
    },
    {
      desc: "Courses that tell the social and religious story of the Jewish people, from its formation through modern times.",
      img: "/images/courseCategories/jewish-history.jpg",
      key: "JewishHistory",
      title: "Jewish History",
    },
    {
      desc: "Courses on ethical dilemmas, civil disputes, policy issues, and business and medical ethics.",
      img: "/images/courseCategories/jewish-ethics.jpg",
      key: "JewishEthics",
      title: "Jewish Ethics",
    },
    {
      desc: "Courses geared toward providing the know-how for performing and understanding the purpose of central Jewish rituals.",
      img: "/images/courseCategories/jewish-living.jpg",
      key: "JewishPracticeAndLiving",
      title: "Jewish Practice and Living",
    },
    {
      desc: "Courses that examine various questions about the Jewish faith, enabling participants to inquire further into their own faith and their ancestral heritage.",
      img: "/images/courseCategories/jewish-theology.jpg",
      key: "JewishTheology",
      title: "Jewish Theology",
    },
    {
      desc: "Courses on kabbalah and Chasidism that inform the quest for transcendence, discipline, and a meaningful life.",
      img: "/images/courseCategories/jewish-mysticism.jpg",
      key: "JewishMysticism",
      title: "Jewish Mysticism",
    },
    {
      desc: "Courses on famous Jewish books, exploring their content, key ideas, history, purpose, and impact on Jewish life.",
      img: "/images/courseCategories/jewish-literature.jpg",
      key: "JewishLiteratureIntro",
      title: "Introduction to Jewish Literature",
    },
    {
      desc: "Text-based study of Jewish works, ancient and modern, for advanced students.",
      img: "/images/courseCategories/jewish-literature-advanced.jpg",
      key: "JewishLiteratureAdvanced",
      title: "Advanced Jewish Literature",
    },
    {
      desc: "Courses exploring Biblical narrative, values, and exegesis.",
      img: "/images/courseCategories/biblical-studies.jpg",
      key: "BiblicalStudies",
      title: "Biblical Studies",
    },
    {
      desc: "Courses that explore the long history and deep-seated connection between Jews and Israel.",
      img: "/images/courseCategories/israel-studies.jpg",
      key: "IsraelStudies",
      title: "Israel Studies",
    },
    {
      desc: "Courses exploring the classical works of Jewish philosophy and addressing philosophical issues from a Jewish perspective.",
      img: "/images/courseCategories/jewish-philosophy.jpg",
      key: "JewishPhilosophy",
      title: "Jewish Philosophy",
    },
    {
      desc: "Courses that enable personal flourishing and well-being, improving relationships, emotions, communication, and success.",
      img: "/images/courseCategories/electives.jpg",
      key: "Electives",
      title: "Electives",
    },
  ];

  render() {
    const {
      myJewishU: { data: { eduChabadHouse: myLocation } = {} },
      sys: {
        jewishUProgram: { activeChabadHousesCount, classesAttendedCount } = {},
      },
      user,
    } = this.props;

    return (
      <>
        <PopupModal
          show={this.state.showPopup}
          handleClose={this.handleClosePopup}
        />
        <div className="home-page page line-height-double">
          <div className="home-page-header">
            <h1 className="home-page-title">
              Explore your Jewish heritage. <br /> Easily. Deeply. Rewardingly.
            </h1>
            <h2 className="home-page-subtitle">
              The easiest way to a Jewish education alongside your college
              degree.
              <br /> No homework. No fees. No sweat.
            </h2>
            <div className="flex flex-justify-center">
              <PageLink
                className="btn btn-accent-secondary"
                params={
                  user && myLocation
                    ? { locationId: myLocation.slug || myLocation.id }
                    : null
                }
                to={
                  user && myLocation
                    ? Pages.locations.locationDetails
                    : Pages.locations.locations
                }
              >
                Begin Today
              </PageLink>
            </div>
          </div>
          <div className="home-page-section mb-80 container">
            <h3 className="home-page-section-title mb-16 text-center">
              Know what's yours
            </h3>
            <p className="large-text line-height-double text-center">
              We know you want to learn more about yourself, your history, and
              heritage.
            </p>
            <p className="large-text line-height-double text-center">
              We also know you’re busy. Like really busy. That’s why we created
              JewishU.{" "}
            </p>
            <div
              className="flex flex-justify-space mobile-block know-whats-yours-icons"
              style={{ maxWidth: 600, margin: "40px auto 0" }}
            >
              <div>
                <div className="img-container">
                  <img
                    src="/images/know-whats-yours-icons/local-learning.svg"
                    alt="local minicourses"
                  />
                </div>
                <p className="xl-text fw-700 text-center line-height-double">
                  Local <br /> learning
                </p>
              </div>
              <div>
                <div className="img-container">
                  <img
                    src="/images/know-whats-yours-icons/local-minicourses.svg"
                    alt="local minicourses"
                  />
                </div>
                <p className="xl-text fw-700 text-center line-height-double">
                  4-lesson <br /> minicourses
                </p>
              </div>
              <div>
                <div className="img-container">
                  <img
                    src="/images/know-whats-yours-icons/rewarding-credits.svg"
                    alt="rewarding credits"
                  />
                </div>
                <p className="xl-text fw-700 text-center line-height-double">
                  Rewarding <br /> credits
                </p>
              </div>
              <div>
                <div className="img-container">
                  <img
                    src="/images/know-whats-yours-icons/customized-experience.svg"
                    alt="customized experience"
                  />
                </div>
                <p className="xl-text fw-700 text-center line-height-double">
                  Customized <br /> experience
                </p>
              </div>
            </div>
          </div>
          <div className="home-page-section two-column-grid-section relative mb-80">
            <div className="two-column-grid-section-left">
              <img src="/images/passion-background.jpg" alt="background" />
            </div>
            <div className="two-column-grid-section-logo logo-center">
              <img
                src="/images/jewish-u-logo-transparent-large.svg"
                alt="jewish u logo"
              />
            </div>
            <div className="two-column-grid-section-right flex flex-align-center flex-justify-center">
              <div className="two-column-grid-section-right-content">
                <h3 className="home-page-section-title mb-24">
                  Find your passion
                </h3>
                <p className="large-text mb-24">
                  From Jewish ethics to mysticism, history to philosophy and so
                  much more, dive into a genre of Jewish wisdom that excites
                  you.
                </p>
                <PageLink className="btn btn-accent" to={Pages.courses.courses}>
                  Browse Courses
                </PageLink>
              </div>
            </div>
          </div>
          <div className="home-page-section mb-80">
            <h3 className="home-page-section-title text-center mb-64 mobile-mb-24">
              A category for every calling
            </h3>
            <div className="carousel-wrapper">
              <div className="carousel noanimate">
                {this.categories.map((category) => {
                  return (
                    <PageLink
                      className="slide"
                      key={category.key}
                      query={{ c: category.key }}
                      to={Pages.courses.courses}
                    >
                      <div className="slide-background-image">
                        <img src={category.img} alt={category.title} />
                      </div>
                      <div className="slide-content">
                        <p className="fw-700 xxl-text mb-16">
                          {category.title}
                        </p>
                        <p className="fw-500 medium-text tablet-hidden mobile-hidden">
                          {category.desc}
                        </p>
                      </div>
                    </PageLink>
                  );
                })}
              </div>
              <nav className="carousel-nav">
                <button
                  className="prev"
                  onClick={(e) => this.onCarouselNavClick(e)}
                >
                  <i className="material-icons prev">arrow_back</i>
                </button>
                <button
                  className="next"
                  onClick={(e) => this.onCarouselNavClick(e)}
                >
                  <i className="material-icons next">arrow_forward</i>
                </button>
              </nav>
            </div>
          </div>
          <div className="home-page-section two-column-grid-section two-column-grid-section-even mb-80">
            <div className="how-it-works">
              <div className="how-it-works-content">
                <h3 className="home-page-section-title accent-text mb-40">
                  How it works
                </h3>
                <div className="how-it-works-steps">
                  <div className="flex flex-align-center mb-24">
                    <div className="relative">
                      <div className="dashed-circle-icon">
                        <img
                          src="/images/how-it-works-icons/enroll.svg"
                          alt="enroll"
                        />
                      </div>
                      <img
                        className="vertical-connector"
                        src="/images/how-it-works-icons/how-it-works-vertical-connector.svg"
                        alt="connecting steps"
                      />
                      <img
                        className="horizontal-connector"
                        src="/images/how-it-works-icons/how-it-works-horizontal-connector.svg"
                        alt="connecting steps"
                      />
                    </div>
                    <div>
                      <p className="xxl-text accent-text-secondary fw-600">
                        Enroll
                      </p>
                      <p className="medium-text">Membership is free and easy</p>
                    </div>
                  </div>
                  <div className="flex flex-align-center mb-24">
                    <div className="relative">
                      <div className="dashed-circle-icon">
                        <img
                          src="/images/how-it-works-icons/select.svg"
                          alt="select"
                        />
                      </div>
                      <img
                        className="vertical-connector"
                        src="/images/how-it-works-icons/how-it-works-vertical-connector.svg"
                        alt="connecting steps"
                      />
                      <img
                        className="horizontal-connector"
                        src="/images/how-it-works-icons/how-it-works-horizontal-connector.svg"
                        alt="connecting steps"
                      />
                    </div>
                    <div>
                      <p className="xxl-text accent-text-secondary fw-600">
                        Select
                      </p>
                      <p className="medium-text">Choose a desired minicourse</p>
                    </div>
                  </div>
                  <div className="flex flex-align-center mb-24">
                    <div className="relative">
                      <div className="dashed-circle-icon">
                        <img
                          src="/images/how-it-works-icons/learn.svg"
                          alt="learn"
                        />
                      </div>
                      <img
                        className="vertical-connector"
                        src="/images/how-it-works-icons/how-it-works-vertical-connector.svg"
                        alt="connecting steps"
                      />
                      <img
                        className="horizontal-connector"
                        src="/images/how-it-works-icons/how-it-works-horizontal-connector.svg"
                        alt="connecting steps"
                      />
                    </div>
                    <div>
                      <p className="xxl-text accent-text-secondary fw-600">
                        Learn
                      </p>
                      <p className="medium-text">Discover your heritage</p>
                    </div>
                  </div>
                  <div className="flex flex-align-center mb-24">
                    <div className="relative">
                      <div className="dashed-circle-icon">
                        <img
                          src="/images/how-it-works-icons/earn.svg"
                          alt="earn"
                        />
                      </div>
                      <img
                        className="vertical-connector"
                        src="/images/how-it-works-icons/how-it-works-vertical-connector.svg"
                        alt="connecting steps"
                      />
                      <img
                        className="horizontal-connector"
                        src="/images/how-it-works-icons/how-it-works-horizontal-connector.svg"
                        alt="connecting steps"
                      />
                    </div>
                    <div>
                      <p className="xxl-text accent-text-secondary fw-600">
                        Earn
                      </p>
                      <p className="medium-text">Accumulate JewishU credits</p>
                    </div>
                  </div>
                  <div className="flex flex-align-center">
                    <div className="relative">
                      <div className="dashed-circle-icon">
                        <img
                          src="/images/how-it-works-icons/repeat.svg"
                          alt="repeat"
                        />
                      </div>
                      <img
                        className="horizontal-connector"
                        src="/images/how-it-works-icons/how-it-works-horizontal-connector.svg"
                        alt="connecting steps"
                      />
                    </div>
                    <div>
                      <p className="xxl-text accent-text-secondary fw-600">
                        Repeat
                      </p>
                      <p className="medium-text">On to your next course</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="knowledge-box">
              <div className="knowledge-box-content">
                <h3 className="knowledge-box-title mb-40">
                  Knowledge that’s rewarding
                </h3>
                <p className="large-text line-height-double">
                  Knowledge is rewarding. So are JewishU credits, which have
                  flexible value opportunities. Choose from our Learn n’ Grow,
                  Learn n’ Give, or Learn n’ Earn options, or save up for a
                  grand trip.
                </p>
                <PageLink
                  className="btn btn-accent-secondary mt-40"
                  to={Pages.credits.credits}
                >
                  Explore your options
                </PageLink>
              </div>
            </div>
          </div>
          <div className="home-page-section container text-center">
            <h3 className="home-page-section-title mb-16">
              Find your JewishU.
            </h3>
            <p className="large-text mb-24">
              JewishU currently offers classes at{" "}
              {formatNumber(activeChabadHousesCount)}{" "}
              {pluralizeText("location", activeChabadHousesCount)}.{" "}
              {formatNumber(classesAttendedCount)}{" "}
              {pluralizeText("class", classesAttendedCount, "classes")} have
              been taken to date.
            </p>
            <PageLink
              className="btn btn-accent-secondary"
              to={Pages.locations.locations}
            >
              Find your local chapter
            </PageLink>
            <p className="italic-text mb-24 text-center mt-40">
              JewishU is a project of Chabad on Campus International.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withLayout()(withAppInsights(Home));
