import React from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

export const PopupModal = ({ show, handleClose }) => {
  return (
    <Dialog open={show} fullWidth maxWidth="lg" style={{ padding: 0 }}>
      <DialogContent style={{ padding: 0 }}>
        <div className="flex flex-align-center tablet-block mobile-block">
          <img
            className="half-width mobile-full-width tablet-full-width popup-image mr-40 mobile-mr-0"
            src="/images/be-the-light.jpg"
            alt="Be the Light"
          />
          <div className="popup-container half-width mobile-full-width tablet-full-width line-height-double">
            <div className="text-right mr-4">
              <IconButton size="small" onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
            <div className="popup-text-container">
              <div className="uppercase-text large-text accent-text-tertiary fw-500 mb-40 mobile-mb-24 mt-24 mobile-mt-0">
                Special for this year:
              </div>
              <div className="mb-8 popup-medium-text">
                <span
                  className="uppercase-text fw-700"
                  style={{
                    backgroundColor: "#1326F3",
                    color: "#FFFFFF",
                    padding: "4px 8px",
                  }}
                >
                  Be the light
                </span>
                <span className="accent-text-secondary fw-500 ml-8">bonus</span>
              </div>
              <div className="accent-text-secondary fw-700 popup-large-text">
                $100 Gift Card
              </div>
              <div className="accent-text-secondary large-text mb-48 mobile-mb-24">
                Upon completing your first JewishU course of the year.
              </div>
              <div className="accent-text-tertiary xxl-text fw-700 mb-64 mobile-mb-24">
                An Educated Jew is an Empowered Jew
              </div>
              <div className="mb-24 popup-small-text">
                Guaranteed for the first{" "}
                <span className="strike-through">1,000</span> 1,500 students
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
