import { AuthRequest } from "../../lib";

const ActivityTypes = {
  Login: "Login",
};

function trackActivity(activityType, entityId) {
  AuthRequest.post("Activity", {
    activityType,
    entityId,
    occurredAt: new Date().toLocaleString(),
  }).catch((err) => {
    if (err.response && err.response.status !== 400) {
      AuthRequest.post(err.config.url, err.config.data).catch((err) =>
        console.log("err", err)
      );
    }
  });
}

export const ActivityActions = {
  SET_ACTIVITY_LOADING: "SET_ACTIVITY_LOADING",
  UPDATE_ACTIVITY: "UPDATE_ACTIVITY",

  getActivity() {
    return async (dispatch) => {
      dispatch(ActivityActions.setActivityLoading(true));

      let success = true;
      const response = await AuthRequest.get("Activity").catch((err) => {
        success = false;
      });

      const data = success
        ? {
            login: response.data.payload.Login,
          }
        : {};

      const payload = {
        ...data,
        loading: false,
        success,
      };

      dispatch(ActivityActions.updateActivity(payload));
    };
  },
  setActivityLoading(payload) {
    return {
      type: ActivityActions.SET_ACTIVITY_LOADING,
      payload,
    };
  },
  updateActivity(payload) {
    return {
      type: ActivityActions.UPDATE_ACTIVITY,
      payload,
    };
  },

  trackLogin() {
    return () => trackActivity(ActivityTypes.Login);
  },
};
